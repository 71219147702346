.nn-review-outer-cont {
    margin: 20px 0 40px;
}
.overviewMap .nn-review-outer-cont h2 {
    display: none;
}
.overviewMap .nnp-cont-inner h2 {
    display: none;
}

.nn-info.home-nn .nn-review-inner-cont > h2, .nn-samap-inner-cont > h2 {
    display: none;
}

.nn-info.home-nn .nn-combo-right > hr {
    display: none;
}

.nn-survey-rating-cat-name > span, .nn-review-rating-cat-name > span {
    position: relative;
    top: 4px;
}

.nn-review-inner-cont > h2, .nn-samap-inner-cont > h2 {
    margin-top: 0;
    margin-bottom: 0;
}

.nn-samap-tc-cont > h4 {
    margin-top: 0;
    margin-bottom: 6px;
}

#nn-review-map-main, #nn-samap-map-main {
    height: 400px;
    margin-bottom: 2em;
    margin-top: 2em;
    width: 100%;
    -webkit-box-shadow: -1px 2px 6px #6c6c6c;
    -moz-box-shadow: -1px 2px 6px #6c6c6c;
    box-shadow: 1px 1px 4px #6c6c6c;
}

#nn-review-map-main.nn-map-medium {
    height: 390px;
}

#nn-review-map-main.nn-map-large {
    height: 480px;
}

.overviewMap #nn-review-map-main.nn-map-large {
    height: 400px;
}

#nn-review-map-main img, #nn-samap-map-main img {
    max-width: none;
}

.noReviewsOrCheckins .nn-combo-right, .noReviewsOrCheckins nn-combo-left {
    display: none;
}

.nn-review-cont hr, .nn-samap-cont hr {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    border-top: 1px dashed #ccc;
    border-bottom: none;
}

.nn-combo-left {
    width: 100%;
    float: left;
}

.nn-combo-right {
    width: 100%;
    float: left;
    background-repeat: repeat-y;
}
.nn-combo-right > hr {
    padding-bottom: 20px;
    background: transparent;
}

.nn-review-cont .nn-review-header time {
    display: none;
}

.nn-combo-right .nn-samap-header h4 {
    padding-top: 0;
}

.nn-review-cont {
    margin-bottom: 1.5em;
}

.nn-time-label {
    font-size: 0.9em;
    font-weight: 700;
    color: #fff;
    vertical-align: baseline;
    background-color: #031f4b;
    padding: 1px 4px 2px;
    margin-bottom: 1.3em;
    margin-top: 0;
    text-align: center;
}

.nn-review-header, .nn-samap-header {
    padding: 0.4em 1.15em;
}

.fullWidth .nn-review-header {
    padding: 0;
}

.nn-review-header h3, .nn-samap-header h3 {
    display: inline;
    font-size: 1.3em;
    margin-top: 0;
}

.nn-review-header h4, .nn-samap-header h4 {
    display: inline-block;
    margin: 0;
}

.nn-samap-header h4 {
    padding-top: 22px;
}

.nn-review-summary, .nn-samap-summary, img.nn-samap-gravatar {
    float: left;
    margin-right: 12px;
}

img.nn-samap-gravatar {
    max-height: 100px;
    max-width: 100px;
    height: 100px;
    width: 100px;
}

.nn-review-map-cont, .nn-samap-map-cont {
    float: right;
    margin-left: 0.7em;
    margin-bottom: 0.7em;
}

.nn-review-map-cont img, .nn-samap-map-cont img {
    border: 1px solid #ccc;
}

p.nn-review-body, p.nn-samap-body {
    padding-left: 3em;
    padding-top: 0.5em;
    margin-left: 1.15px;
    margin-top: 0.7em;
    min-height: 24px;
}

img.nn-samap-ci-image {
    max-height: 240px;
    max-width: 240px;
}

.nn-samap-ci-video {
    max-height: 320px;
    max-width: 320px;
}

.nn-review-footer {
    background-color: #f5f5f5;
    padding: 1.08em 1.15em 1.15em 2em;
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
    border-top: 1px solid #DDD;
    -webkit-box-shadow: inset 0 1px 0 #fff;
    -moz-box-shadow: inset 0 1px 0 #fff;
    box-shadow: inset 0 1px 0 #fff;
    zoom: 1;
    text-align: left;
}

.fullWidth .nn-review-footer {
    margin: 0;
}

.nn-review-star-wrapper {
    float: left;
    width: 110px;
    overflow: auto;
    display: inline-block;
}

.nn-review-rating-cat-overall {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #777;
}

.nn-review-rating-cat-name {
    float: left;
    width: 14em;
}

.nn-review-star {
    float: left;
    display: block;
    overflow: hidden;
    text-indent: -999em;
    width: 28px;
    height: 26px;
    position: relative;
    background: url(https://d6at0twdth9j2.cloudfront.net/assets/images/nn-review-stars.png) 0 -56px no-repeat;
}

.nn-review-star-on {
    background-position: 0 -84px;
}

.nn-samap-topcity {
    float: left;
    width: 33%;
}

.nn-checkin-header, nn-survey-header {
    padding: 0.4em 1.15em;
}

.nn-checkin-header h3, .nn-survey-header h3 {
    display: inline;
    font-size: 1.3em;
    margin-top: 0;
}

.nn-checkin-header h4, .nn-survey-header h4, .nn-survey-header h5 {
    padding-top: 5px;
    display: inline-block;
    margin: 0;
}

.nn-checkin-map-cont, .nn-survey-map-cont {
    float: right;
    margin-left: 0.7em;
    margin-bottom: 0.7em;
}

.nn-checkin-map-cont img, .nn-survey-map-cont img {
    border: 1px solid #ccc;
}

.nn-survey-header blockquote, p.nn-checkin-reference, p.nn-survey-body {
    padding-left: 0.5em;
    margin-top: 0.7em;
    background: 0 0;
    border-left: 4px solid #eee;
}

p.nn-checkin-reference, p.nn-survey-body {
    margin-left: 1.15px;
    min-height: 18px;
}

.nn-survey-header blockquote {
    margin-left: 20px;
    margin-bottom: 3px;
}

.nn-survey-header blockquote p {
    margin-bottom: 10px;
}

.nn-survey-footer {
    background-color: #f5f5f5;
    padding: 1.08em 1.15em 1.15em 2em;
    margin-bottom: 0;
    border-top: 1px solid #DDD;
    -webkit-box-shadow: inset 0 1px 0 #fff;
    -moz-box-shadow: inset 0 1px 0 #fff;
    box-shadow: inset 0 1px 0 #fff;
    zoom: 1;
    text-align: left;
}

.nn-survey-rating-cat-overall {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #777;
}

.nn-survey-rating-cat-name {
    float: left;
    width: 14em;
}

.nn-survey-star-wrapper {
    float: left;
    width: 110px;
    overflow: auto;
    display: inline-block;
}

.nn-review-inner-cont .empty {
    width: 0;
    display: none;
}

.nn-review-inner-cont .fullWidth {
    width: 100%;
}

.fullWidth .nn-samap-header > a {
    max-width: 334px;
    display: block;
}

.fullWidth .nn-samap-header {
    padding: 1.15em 0;
    position: relative;
    border-bottom: 1px dashed #bfbfbf;
    display: flex;
}

.nn-combo-right.fullWidth {
    background-image: none;
}

.checkinBody {
    width: 100%;
}

.containsMedia {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 15px;
}
.containsMedia a {
    max-width: 300px;
}

.citiesCloud {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 20px;
}

.nn-combo-left.fullWidth > :not(.nn-review-cont) {
    display: none;
}

@media only screen and (max-device-width: 667px) {
    .nn-review-outer-cont {
        font-size: 180% !important;
    }
    .nn-combo-left, .nn-combo-right {
        width: 100% !important;
    }
    p.nn-review-body, p.nn-samap-body {
        padding-left: 0.5em !important;
        margin-left: 0 !important;
        background-image: none !important;
    }
    .nn-review-footer {
        margin-left: 3px !important;
        margin-right: 3px !important;
        padding: 1em !important;
    }
}
@media only screen and (max-width: 992px) {
    .nn-review-inner-cont, .nn-samap-inner-cont {
        font-size: 0.9em !important;
    }
    .nn-combo-left {
        width: 100% !important;
    }
    .nn-combo-right {
        width: 100% !important;
    }
    p.nn-review-body, p.nn-samap-body {
        padding-left: 0.5em !important;
        margin-left: 0 !important;
        background-image: none !important;
    }
    .nn-review-footer {
        margin-left: 3px !important;
        margin-right: 3px !important;
        padding: 1em !important;
    }
}
blockquote.nnp-rv-body {
    margin: 10px 0 !important;
}
.cloudContainer {
    padding: 25px 0 50px;
}
.home.cloudContainer {
    padding: 25px 0;
}
.cloudContainer h3 {
    margin-bottom: 1em;
}
.cloudContainer .citiesCloud a,.cloudContainer .citiesList li a {
    font-weight: 600;
    text-decoration: none;
}
.home.cloudContainer .citiesCloud {
    justify-content: right;
}
.cloudContainer .citiesCloud a svg,.cloudContainer .citiesList li a svg {
    height: 14px;
    margin-right: 10px;
}
.cloudContainer .citiesCloud a:hover:after,.cloudContainer .citiesList li a:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
}
.cloudContainer .citiesCloud a:after,.cloudContainer .citiesList li a:after {
    display: block;
    content: "";
    border-bottom: 2px solid var(--main-color);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
}
.overviewMap .nn-review-outer-cont {
    margin: auto;
}
.cloudContainer .citiesList li {
    display: flex;
    width: 25%;
    float: left;
}
@media only screen and (max-width: 1024px) {
    .cloudContainer .citiesList li {
        width: 33.33333%;
    }
}
@media only screen and (max-width: 768px) {
    .cloudContainer .citiesList li {
        width: 50%;
    }
}
@media only screen and (max-width: 680px) {
    .cloudContainer .citiesList li {
        width: 100%;
    }
}
